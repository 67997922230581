import React, { Component } from 'react'
import {Link } from "react-router-dom";
import Header from '../component/header'
import '../style/album.css'
import {
    SiteArticleList
  } from "../api/api";
export default class Album extends Component {
    state = {
        albumList: [ ],
        
    }
    componentWillMount(){
        const params = new URLSearchParams(this.props.location.search)
        if (params.get("desin_id")) {
            this.SiteArticleList(params.get("desin_id"))
        }
    }
    async SiteArticleList(desin_id){
        let res = await SiteArticleList({desin_id:desin_id})
        if(res.code == 0){
          this.setState({
            albumList: res.data.list
          })
        }
      }
    render() {
        let list = ''
            list = <div className="album_list">
                {
                    this.state.albumList.map((val,index)=>{
                        return <Link key={index} to={{pathname:"/details", search:`?id=${val.id}`}}>
                            <div className="item">
                            <img alt="" src={val.cover} />
                            <p className="title">{val.title}</p>
                            <p className="btn">
                                <span>查看专辑</span> 
                                <img alt="" src={require('../assets/img/more.png')} />
                            </p>
                        </div></Link>
                    })
                }
            </div>
        return (
            <div >
                <Header  city={true} />
                {list}
            </div>
        )
    }
}