import React, { Component } from 'react';
import {Redirect,Link} from "react-router-dom";
import {
    SiteHome
  } from "../api/api";
// @withRouter
class NAVBAR extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        data:{
            catetory:[{
              id:1,
              title:'客片欣赏'
          },
          {
              id:2,
              title:'VLOG视频'
          },
          {
              id:3,
              title:'旅拍目的地'
          },
          {
              id:4,
              title:'优惠活动'
          },
          // {
          //   id:6,
          //   title:'路图APP'
          // },
          {
              id:5,
              title:'公司介绍'
          }]
          },
          Flag:false,
          city:false
    }
    componentWillMount(){
        // this.SiteHome()
        if (this.props.city) {
          this.setState({
            city:true
          })
        }
      }
    
    keFu(){
        window.ysf('open', {
            templateId:123
        });
      }
    CityScroll(){
        let anchorElement = document.getElementById('city')
        if(anchorElement) {
            setTimeout(() => {
            anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"}) 
            }, 100)
        }
      }
    checkBoxEnd(){
        var checkboxs = document.getElementById("nav-check")
        checkboxs.checked = false
      }
      cityHtml(){
        console.log(this.state.city)
        if (this.state.city) {
          return <Link key="旅拍目的地" to={{pathname:"/", state:{city:true}}}> 旅拍目的地 </Link>                 
        } else {
          return <span key="旅拍目的地" onClick={()=>{this.CityScroll()}}> 旅拍目的地</span> 
        }
      }
    render() {
        return (
            <div>
                <div className="nav_bar" >
                <div className="caidan" >
                  <img src={require('./../assets/img/caidan.png')} alt=""/>
                  <input type="checkbox" className="nav-check" id="nav-check" />
                  <div className="nav_list" onClick={()=>{this.checkBoxEnd('1')}}>
                    {
                      this.state.data.catetory.map(item=>{
                        switch (Number(item.id)) {
                          case 1:
                            return <Link key={item.title} to={{pathname:"/album1", state:{ac_id:item.id}}} > {item.title} </Link>
                            break;
                          case 2:
                            return <Link key={item.title} to={{pathname:"/vlog", state:{ac_id:item.id}}}> {item.title} </Link>
                            break;
                          case 3:
                            return this.cityHtml()
                            break;
                          case 4:
                            return <span key={item.title} onClick={()=>{this.keFu()}}> {item.title}</span> 
                            break;
                          case 5:
                            return <Link key={item.title} to={{pathname:"/introduce", state:{ac_id:item.id}}}> {item.title} </Link>
                            break;
                          case 6:
                            return <Link key={item.title} to={{pathname:"/download",}}> {item.title} </Link>
                            break;
                          default:
                            break;
                        }
                      })
                    }
                  </div>
                  <div className="mask"></div>
                    
                </div>
                <Link to="/">
                <img alt="" className="logo" src={require('./../assets/img/logo.png')} />
                </Link>
                <div  className="caidan"></div>
            </div>
            <div className="nav_bar_box">
          </div>
            </div>
        )
    }
}
export default NAVBAR