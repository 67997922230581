import React from 'react';
import ReactDOM from 'react-dom';
import 'antd-mobile/dist/antd-mobile.css';
import "../node_modules/video-react/dist/video-react.css";
import './style/public.css'
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
// if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
// 	// window.location.href = 'http://m.rotophoto.cn'
// } else {
// 	window.location.href = 'http://www.rotophoto.cn'
// }