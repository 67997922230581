import React, { Component } from 'react';
import { Player } from 'video-react';
import Header from '../component/header'
import {
    SiteArticleList
  } from "../api/api";
import '../style/vlog.css'
export default class Vlog extends Component{
    state={
        vlogList:[ ]
    }
    componentWillMount(){
            this.SiteArticleList(2)
    }
    async SiteArticleList(ac_id){
        let res = await SiteArticleList({ac_id:ac_id})
        if(res.code == 0){
          this.setState({
            vlogList: res.data.list
          })
          console.log(this.state.vlogList)
        }
      }
    render() {
        return (
        <div><Header  city={true} />
            <div className="vlog_list">
            {
                this.state.vlogList.map((item,index)=>{
                    return <div key={index} className="item">
                        {item.path}
                            {/* <Player poster={item.cover} aspectRatio="16:9" className="videoPlay" ref="player" videoId={'vlog'+index}>
                                <source src={item.atc_attach[0].file} />
                            </Player> */}
                            <video controls src={item.atc_attach[0].file} poster={item.cover} />
                            <p className="title">{item.title}</p>
                        </div>
                })
            }
            </div>
        </div>
        )
    }
}