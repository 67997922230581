import React, { Component } from 'react';
import Header from '../component/header'
import '../style/download.css'
import moment from "moment";
import {
    DownloadUrl
  } from "../api/api";
export default class Download extends Component {
    state={
        data:{
            
        }
    }
    componentDidMount(){
        this.DownloadUrl()
    }
    async DownloadUrl(){
        let res = await DownloadUrl({app_id:8})
        if(res.code === 0){
          this.setState({
            data: res.data
          })
          
        }
    }
    download(type){
        console.log(this.state.data[type])
        window.location.href = this.state.data[type]
    }
    render() {
        return( <div>
            <Header  city={true} />
                <div className="download_header">
                    <img className="logo" alt="" src={require('./../assets/img/icon.png')} />
                    <div className="name">路图</div>
                    <div className="title">记录美好，分享快乐</div>
                    <div className="download">
                        <img onClick={()=>{this.download('android')}} alt="" src={require('./../assets/img/android.png')} /> 
                        <img onClick={()=>{this.download('ios')}} alt="" src={require('./../assets/img/ios.png')} />
                    </div>
                </div>
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-1.png')} />
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-2.png')} />
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-3.png')} />
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-4.png')} />
            </div>
        )
    }
}