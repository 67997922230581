//引入react jsx写法的必须
//引入需要用到的页面组件 
import Home from './pages/home';
// import Index from './pages/index';
import Vlog from './pages/vlog';
import Album from './pages/album';
import Album1 from './pages/album1';
import Album2 from './pages/album2';
import Album3 from './pages/album3';
import Album4 from './pages/album4';
import Bourn from './pages/bourn';
import Introduce from './pages/introduce';
import Details from './pages/details';
import Download from './pages/download';

//引入一些模块

let router = [
    
    {
        path: "/",
        title: "首页",
        component: Home,
        exact: true
    },
    {
        path: "/vlog",
        title: "Vlog",
        component: Vlog,
        exact: true
    },
    {
        path: "/introduce",
        title: "introduce",
        component: Introduce,
        exact: true
    },
    {
        title: "列表一",
        path: "/album",
        component:Album,
        exact: true
    },
    {
        title: "列表一",
        path: "/album1",
        component:Album1,
        exact: true
    },
    {
        title: "列表一",
        path: "/album2",
        component:Album2,
        exact: true
    },
    {
        title: "列表一",
        path: "/album3",
        component:Album3,
        exact: true
    },
    {
        title: "列表一",
        path: "/album4",
        component:Album4,
        exact: true
    },
    {
        path: "/bourn",
        title: "Bourn",
        component: Bourn,
        exact: true
    },
    {
        path: "/details",
        title: "Details",
        component: Details,
        exact: true
    },
    {
        path: "/download",
        title: "Download",
        component: Download,
        exact: true
    },
];

export default router;