import React, { Component } from 'react'
import {Link } from "react-router-dom";
import Header from '../component/header'
import '../style/album.css'
import {
    SiteArticleList
  } from "../api/api";
export default class Album extends Component {
    state = {
        albumList: [ ],
        tabs: [
            { title: '情侣' ,id:1 },
            { title: '个人' ,id:2 },
            { title: '亲子' ,id:3 },
            { title: '闺蜜' ,id:4 },
        ],
        activeTab:1,
        id:1 
    }
    componentWillMount(){
        this.SiteArticleList(this.state.id)
    }
    tabChange(tab){
        this.setState({
            id: tab
        })
        this.SiteArticleList(tab)
    }
    async SiteArticleList(ac_id){
        let res = await SiteArticleList({al_ids:ac_id})
        if(res.code == 0){
          this.setState({
            albumList: res.data.list
          })
      
        }
      }
    render() {
        let list =<div> 
            <Header city={true} />
            <div className="tabs">
            {
                this.state.tabs.map(item=>{
                    return <span 
                    // onClick={()=>{this.tabChange(item.id)}}  
                    className={this.state.id==item.id?'active':''} 
                    key={item.id}><Link to={{pathname:`/album${item.id}`, search:`?ac_id=${item.id}`}} > {item.title}</Link></span> 
                })
            }
        </div>
        <div className="album_list">
                         <div  style={{ backgroundColor: '#fff' }}
                            className="tab_item" >
                            {
                                this.state.albumList.map((val,index)=>{
                                    return <Link key={index} to={{pathname:"/details", search:`?id=${val.id}`}}>
                                        <div key={index} className="item">
                                        <img alt="" src={val.cover} />
                                        <p className="title">{val.title}</p>
                                        <p className="btn">
                                            <span>查看专辑</span> 
                                            <img alt="" src={require('../assets/img/more.png')} />
                                        </p>
                                    </div></Link>
                                })
                            }
                        </div>
                    </div></div>
        
        return (
            <div >
                {list}
            </div>
        )
    }
}