import React, { Component } from 'react';
import Header from '../component/header'
import '../style/details.css'
import {
    SiteArticleView
  } from "../api/api";
export default class Details extends Component {
    state={
        data:{
        }
    }
    componentWillMount(){
        const params = new URLSearchParams(this.props.location.search)
        if (params.get("id")) {
            this.SiteArticleView(params.get("id"))
        }
    }
    componentDidMount(){
    }
    async SiteArticleView(id){
        let res = await SiteArticleView({id:id})
        if(res.code === 0){
          this.setState({
            data: res.data
          })
        }
    }
    render() {
        let content =''
        if (this.state.data.type == 1) { //图片列表
            content = <div className="details">
                {
                    this.state.data.fileArray.map(item=>{
                        return <img key={item} src={item} alt=""/>
                    })
                }
            </div>
        }else if (this.state.data.type == 2) { //视频
            content = <div>
                <video controls src={this.state.data.fileArray[0]} />
            </div>
        }else if (this.state.data.type == 3) { //图文
            content = <div className="details">
                <div className="title">
                    {this.state.data.title}
                </div>
                <div className="content" dangerouslySetInnerHTML={{__html: this.state.data.content}} />
            </div>
        }else {
        }

        return(
            <div>
                <Header  city={true} />
                {content}
            </div>
        )
    }
}