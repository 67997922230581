import React, { Component } from 'react';
import router from '../Router';
import PropTypes from 'prop-types'
import { NavBar, Icon, Popover } from 'antd-mobile';
import Footer from './footer.js'

import { BrowserRouter as Router, Route } from "react-router-dom";
const Item = Popover.Item;

export default class About extends Component {
  constructor(props) {
    super(props);
}

componentDidMount(){
}

  render() {
    
    return (
      <div>
        <Router>
          
          <div className="content_box">
          {
            router.map(item=>{
              return (<Route key={item.path} exact={item.exact} path={item.path} component={item.component} />)
            })
          }
          </div>
          <div  className="footer_box">
          <Footer  />
          </div>
        </Router>
        
      </div>)
  }
}
