import React, { Component } from 'react';
import { Carousel, WingBlank } from 'antd-mobile';
import { Player } from 'video-react';
import {Link} from "react-router-dom";
import Header from '../component/header'
import {
    SiteHome
  } from "../api/api";
import '../style/home.css'
export default class Home extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        data: {
            bannerOne:[],
            bannerTow:[],
            countries:[],
            desination:[],
            vlog:{list:[{atc_attach:[{file:''}]}]}
        },
        
        slideIndex:0,
        slideCurrent:0
    }
    CityScroll(){
        let anchorElement = document.getElementById('city')
        if(anchorElement) {
            setTimeout(() => {
            anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"}) 
            }, 30)
        }
    }
    componentDidMount(){
        this.SiteHome()
        
      }
      async SiteHome(){
        let res = await SiteHome()
        if(res.code == 0){
          this.setState({
            data: res.data
          })
      
        }
      }
    render() {
        var vlog = ''
        if (this.state.data.vlog.file) {
            vlog = <div>
                    <div className="title_img " >
                        <img alt="" className=" t2" src={require('./../assets/img/t2.png')} />
                    </div>
                    <video  controls src={this.state.data.vlog.file}  poster={this.state.data.vlog.cover} />
            </div>
        }
        if(this.props.location.state){
            if(this.props.location.state.city){
                this.CityScroll()
            }
        }
        return (
            <div>
                <Header />
                <WingBlank key='banner-carousel' className="banner-carousel">
                    <Carousel
                        frameOverflow="visible"
                        //   autoplay
                        infinite
                        beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                        afterChange={index => console.log('slide to', index)}
                        dotStyle={{
                            marginBottom: '2.667vw',
                            width: '1.067vw',
                            height: '1.067vw',
                        }}
                        dotActiveStyle={{
                            marginBottom: '2.667vw',
                            width: '1.067vw',
                            height: '1.067vw',
                            background: '#fff'
                        }}
                    >
                        {this.state.data.bannerOne.map((val, index) => (
                            <a key={val}  style={{
                                display: 'block',
                                position: 'relative',
                            }}>
                                <img alt="" src={val.img} style={{ 
                                        width: '100%', height: '46.667vw' }}/>
                            </a>
                        ))}
                    </Carousel>
                </WingBlank>
                <div className="title_img" onClick={() => this.props.history.push('/album')}>
                    <img alt="" className=" t1" src={require('./../assets/img/t1.png')} />
                </div>
                <WingBlank  key='space-carousel' className="space-carousel">
                    <Carousel
                        frameOverflow="visible"
                        cellSpacing={4}
                        slideWidth={0.88}
                        // autoplay
                        infinite
                        beforeChange={(from, to) => {}}
                        afterChange={index => {this.setState({slideCurrent:index})}}
                        dotStyle={{
                            width: `22.4vw`,
                            height: '0.534vw',
                            background: '#ccc',
                            borderRadius:'inherit',
                            margin: 0,
                        }}
                        dotActiveStyle={{
                            width: `22.4vw`,
                            height: '0.534vw',
                            background: '#040B29',
                            borderRadius:'inherit',
                            margin: 0,
                        }}
                    >
                        {this.state.data.bannerTow.map((val, index) => (
                            <a key={val}
                                style={{
                                    display: 'block',
                                    position: 'relative',
                                    height:'46.867vw'
                                }}
                            >
                                <img
                                    src={val.img}
                                    alt=""
                                    style={{ 
                                        width: '100%',
                                        height: this.state.slideCurrent === index ? '41.867vw'  : '39.2vw',
                                        top: this.state.slideCurrent === index ? '0'  : '1.334vw',
                                        position: 'absolute'
                                        }}
                                />
                            </a>
                        ))}
                    </Carousel>
                </WingBlank>
                {vlog}
                <div className="title_img " >
                    <img alt="" className=" t3" src={require('./../assets/img/t3.png')} />
                </div>
                <div className="countries" id="city">
                    {
                        this.state.data.desination.map((item,index)=>{
                            return <Link  key={index} to={{pathname:"/bourn", search:`?desin_id=${item.id}`}}> 
                                <div className="item" key={index}>
                                    <img alt="" src={item.cover||require(`./../assets/temporary/c1.png`)}/>
                                    
                                    <div className="child">
                                        <p className="title">{item.name}</p>
                                        <p >{item.shorttitle}</p>
                                    </div>
                                </div></Link>
                                
                        })
                    }
                </div>
            </div>


        )
    }
}