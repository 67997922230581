import React from 'react';
import router from './Router'
import Index from './component/index'


function App() {
  return (
    <div className="App">
      <Index />
    </div>
  )
}

export default App;
