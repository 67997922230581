import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Header from '../component/header'
import '../style/introduce.css'
import moment from "moment";
import {
    SitePageList
  } from "../api/api";
export default class Introduce extends Component {
    state={
        data:{
            page:{
                img:'http://new.rototravel.cn/uploads/191107/1-19110G21640959.png',
                content:'<p>2018年9月，总公司正式落户北京。</p><p>2016年4月，上海路图信息科技有限公司正式成立，简称“路图旅拍”。</p><p>2016年4月，上海路图信息科技有限公司正式成立，简称“路图旅拍”。</p>'
            },
            list:{
                list:[ ]
            }
        }
    }
    componentDidMount(){
        this.SitePageList()
    }
    async SitePageList(){
        let res = await SitePageList()
        if(res.code === 0){
          this.setState({
            data: res.data
          })

        }
    }
    render() {
        return( <div><Header  city={true} />
            <div className="introduce">
                {/* <img alt="" src={this.state.data.info.img} className="banner"/> */}
                <div className="news">
                    <div className="title">公司新闻</div>
                    {
                       this.state.data.list.list.map((item,index)=>{
                           return <Link  key={index} to={{pathname:"/details",  search:`?id=${item.id}`}}>  <div className="item">
                                    <div className="title">{item.title}</div>
                                    <div className="time">{moment(parseInt(item.create_time*1000)).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div></Link>
                       }) 
                    }
                </div>
                <div className="story">
                    <div className="title"> 品牌故事</div>
                    <div className="content" dangerouslySetInnerHTML={{__html: this.state.data.page.content}} />
                </div>
            </div></div>
        )
    }
}